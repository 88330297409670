<template>
  <div v-if="document" class="pt-3">
    <page-breadcrumb :title="`Category Detail`" :items="breadcrumbItems" />

    <section class="my-3">
      <h2 class="h2">Speaking Overview</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="3">
            <strong class="h6">Thumbnail</strong>
            <ui-component :component-data="document.thumbnail" class="border" />
          </b-col>
          <b-col cols="9">
            <strong class="h6">Title</strong>
            <b-form-input class="mb-3" type="text" v-model="document.title" placeholder="Enter your title here" />

          </b-col>
        </b-row>
      </b-card>
    </section>

    <section class="mt-5">
      <div class="d-flex justify-content-between mt-3">
        <h2 class="h2">Lesson List</h2>
        <div class="d-flex">
          <b-button pill class="ml-1"
            :variant="selected_level === 1 ? 'outline-primary' :'outline-dark'"
            @click="selected_level = 1"
          >Easy</b-button>
          <b-button pill class="ml-1"
            :variant="selected_level === 2 ? 'outline-primary' :'outline-dark'"
            @click="selected_level = 2"
          >Normal</b-button>
          <b-button pill class="ml-1"
            :variant="selected_level === 3 ? 'outline-primary' :'outline-dark'"
            @click="selected_level = 3"
          >Hard</b-button>
        </div>
        <div class="d-flex ">
          <loading-button icon="CheckCircleIcon" variant="warning"
            :disabled="!is_order_changed" @click="updateOrder" :is-loading="is_updating_lesson_order"
          >Update Display Order</loading-button>

          <lesson-create-form class="ml-1"
            :display_order="lesson_list.length - 1"
            :category_key="document.key"
            :level="selected_level"
            @created="addNewLesson"
          />

        </div>
      </div>
      <b-row class="mt-1" style="min-height: 100vh">
        <draggable v-model="lesson_list" class="w-100" v-if="!is_loading_lesson"
          handle=".grab-item"
        >
          <transition-group type="transition">
            <b-col cols="3" v-for="(lesson) in lesson_list" :key="lesson._id" class="grab-item">
              <lesson-card :lesson="lesson" />
            </b-col>
          </transition-group>
        </draggable>
      </b-row>
    </section>
    <action-buttons update-button back-button delete-button
      @update="update" :is-updating="isUpdating" @delete="deleteDocument" :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service'
import lessonService from '../../arena_lesson/service'
import LessonCreateForm from './_components/LessonCreateForm.vue'
import LessonCard from './_components/LessonCard.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    LessonCreateForm,
    LessonCard,
    draggable,
  },
  data() {
    return {
      document: null,
      isUpdating: false,
      isDeleting: false,
      isUpdatingBot: false,
      lesson_list: [],
      total_page: 0,
      is_order_changed: false,
      is_updating_lesson_order: false,
      is_loading_lesson: false,
      selected_level: 1,
    }
  },
  computed: {
    documentId() {
      return this.$route.params.category_id
    },
    breadcrumbItems() {
      let items = [{
        text: 'Category List',
        to: { name: 'arena-category-list' },
      }]
      items.push({ text: `${this.document.title || this.document._id}`, active: true })
      return items
    },
  },
  watch: {
    lesson_list(value, old_value) {
      if (!old_value.length) { return }
      if (this.is_order_changed === false) {
        this.is_order_changed = true
      }
    },
    selected_level() {
      this.getLessonList()
    },
  },
  async created() {
    await this.getDocument()
    this.getLessonList()
  },
  methods: {
    async getDocument() {
      this.document = await service.get({ id: this.documentId })
    },
    async update() {
      const response = await service.update({ data: JSON.stringify(this.document) })
      if (response.data.type === 'DATA') {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully updated document!' })
      }
      this.getDocument();
      this.isUpdating = false
    },
    async deleteDocument() {
      this.isDeleting = true
      const response = await service.delete({ id: this.documentId })
      if (response.data.type === 'DATA') {
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully deleted document!' })
      }
      this.isDeleting = false
      this.$router.push({ name: 'arena-category-list' })
    },
    async getLessonList() {
      this.is_loading_lesson = true
      let data = await lessonService.getList({
        query: encodeURIComponent(JSON.stringify({ category_key: this.document.key, level: this.selected_level })),
        order_by: 'display_order',
        order: 1,
      })
      if (!data) { return }
      this.is_loading_lesson = false
      this.lesson_list = data.list
      this.total_page = data.total_page
    },
    async updateOrder() {
      let list = this.lesson_list.map(item => { return { _id: item._id, display_order: item.display_order } })
      let response = await lessonService.updateOrder({ list })
      if (response.data.success) {
        this.is_order_changed = false
        this.$store.dispatch('pushSuccessNotify', { text: 'Successfully update lessons order!' })
      }
    },
    addNewLesson(lesson) {
      this.lesson_list.push(lesson)
    },
  },
}
</script>

<style scoped>
.question-item {
  transition: ease 0.6s
}
</style>

<style>
.grab-item {
  transition: ease 0.3s
}

.row > div > span{
  display: flex;
  width: 100%;
  clear: both;
  flex-flow: wrap;
}
</style>
