import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/arena-categories', {
      params,
    })
    return response.data.data || null
  },
  async get(params) {
    let response = await api.get('/admin/arena-categories/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async update(payload) {
    let response = await api.put('/admin/arena-categories', payload)
    return response
  },
  async updateBot(payload) {
    let response = await api.put('/admin/arena-categories/sample-records', payload)
    return response
  },
  async create(payload) {
    const response = await api.post('/admin/arena-categories', payload)
    return response.data.data.new_document || null
  },
  async delete(payload) {
    const response = await api.delete('/admin/arena-categories', {
      data: payload,
    })
    return response
  },
  async updateOrder(payload) {
    const response = await api.post('/admin/arena-categories/update-order', payload)
    return response
  },

}
