<template>
  <loading-button icon="PlusIcon" :is-loading="is_loading" @click="createLesson">Add New</loading-button>
</template>

<script>
import service from '../../../arena_lesson/service'

export default {
  props: {
    display_order: { type: Number, required: true },
    level: { type: Number, required: true },
    category_key: { type: String, required: true },
  },
  data() {
    return {
      is_loading: false,
    }
  },
  methods: {
    async createLesson() {
      this.is_loading = true
      let new_lesson = await service.create({ display_order: this.display_order, category_key: this.category_key, level: this.level })
      this.is_loading = false
      this.$emit('created', new_lesson)
    },
  },
}
</script>
